import React from "react";
import { get } from "lodash";

import book from "../../content/book.json";
import home from "../../content/home.json";

const CONTENT = { book, home };

export function content(from, at) {
  return get(CONTENT[from], at, "CONTENT_NOT_FOUND");
}

export function Content({ from, at, ...rest }) {
  return content(from, at);
}

export function HomeContent(props) {
  return <Content from="home" {...props} />;
}

export function homeContent(at) {
  return content("home", at);
}

export function BookContent(props) {
  return <Content from="book" {...props} />;
}

export function bookContent(at) {
  return content("book", at);
}
